<template>
    <van-dialog
            v-model="dialogShow"
            width="100%"
            class="upload-sample"
            title=""
            :showConfirmButton="false"
            :showCancelButton="false"
            :class="isUpload?'isUpload':''"
    >
        <van-uploader
                ref="uploadImg"
                v-model="fileList"
                capture="camera"
                accept="image/*"
                :preview-image="false"
                :preview-full-image="false"
                :before-read="beforeReadAnswer"
                :after-read="afterReadAnswer"
                style="display:none;"
        >
        </van-uploader>
        <template v-if="!isUpload">
            <!--作品-->
            <div class="hint-txt" v-if="formData.type==1">
                参照示例拍照上传。<span>须确保作品在照片中完整、清晰呈现。拍照时尽量让考试用纸四边与矩形拍照区域的四边对齐。</span>
            </div>
            <!--试卷袋-->
            <div class="hint-txt" v-if="formData.type==2">
                参照示例拍照上传。<span>照片须清晰呈现完整的正式寄回袋（封口面）及“清华大学美术学院专业试卷密封条”。</span>
            </div>
            <div class="hint-img">
                <van-image :src="formData.hintImg"></van-image>
                <div class="img-txt">示例图片</div>
            </div>
            <div class="hint-btn">
                <van-button type="primary" :loading="loading" loading-text="上传中..." class="confirm" @click="onConfirm">
                    拍照上传
                </van-button>
                <van-button type="text" class="cancel" @click="onCancel">取消</van-button>
            </div>
        </template>
        <template v-if="isUpload">
            <!--作品-->
            <div class="hint-txt" v-if="formData.type==1">
                请检查并确认所拍摄照片符合要求。<span>须确保作品在照片中完整、清晰呈现。拍照时尽量让考试用纸四边与矩形拍照区域的四边对齐。</span>
            </div>
            <!--试卷袋-->
            <div class="hint-txt" v-if="formData.type==2">
                请检查并确认所拍摄照片符合要求。<span>照片须清晰呈现完整的正式寄回袋（封口面）及“清华大学美术学院专业试卷密封条”。</span>
            </div>
            <div class="hint-img">
                <van-image :src="formData.hintImg"></van-image>
                <div class="img-txt">示例图片</div>
            </div>
            <div class="hint-img">
                <van-image :src="imgUrl"></van-image>
                <div class="img-txt">我的图片</div>
            </div>
            <div class="hint-btn">
                <div class="commit-btn">
                    <van-button type="primary" class="cancel" @click="onConfirm">重新拍照</van-button>
                    <van-button type="primary" class="confirm" @click="onCommit">提交照片</van-button>
                </div>
                <van-button type="text" class="cancel" @click="onCancel">取消拍照</van-button>
            </div>
        </template>
    </van-dialog>
</template>

<script>
    import {Toast} from "vant";
    import {nanoid} from 'nanoid'
    import {getSignature} from "r/index/index";
    import {fileUpload} from "r/index/upload";


    export default {
        name: "UploadWorks",
        props: {
            dialogShowFu: [Boolean],
            formDataFu: [Object, String]
        },
        data() {
            return {
                fileList: [],
                imgUrl: null,
                dialogShow: this.dialogShowFu,
                formData: this.formDataFu,
                loading: false,
                isUpload: false,// true-已上传 false-未上传
            }
        },
        methods: {
            // 提交照片
            onCommit() {
                this.fileList = [];
                this.isUpload = false;
                this.dialogShow = false;
                this.$emit('closeDialog', this.dialogShow, this.imgUrl, this.formData.type, this.formData.idx1, this.formData.idx2);
                this.imgUrl = null;
            },
            // 拍照上传
            onConfirm() {
                this.fileList = [];
                this.$refs['uploadImg'].chooseFile();
            },
            // 取消
            onCancel() {
                this.imgUrl = null;
                this.fileList = [];
                this.isUpload = false;
                this.dialogShow = false;
                this.$emit('closeDialog', this.dialogShow, false, false, false, false);
            },
            // 上传作品-读取文件
            beforeReadAnswer(file) {
                this.loading = true;
                return file;
            },
            // 上传
            afterReadAnswer(file) {
                if (this.formData.type == 1) {
                    this.uploadHandle(file, this.formData.idx1, this.formData.idx2, this.formData.subjectId);
                } else if (this.formData.type == 2) {
                    this.uploadBagHandle(file);
                }
            },
            // 作品上传
            async uploadHandle(file, idx1, idx2, subjectId) {
                file.status = 'uploading';
                file.message = '上传中...';
                const zkz = localStorage.getItem('monitor-zkz');
                const fileName = 'answer/' + zkz + '_' + subjectId + '_' + nanoid() + '_' + idx2 + '.jpg';

                let signatureOss = window.localStorage.getItem('monitor-signature-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem('monitor-subjectUuId'));
                if (signatureOss && signatureOss !== '{}' && Object.keys(JSON.parse(signatureOss)).length > 0) {
                    const data = JSON.parse(signatureOss);
                    await this.uploadRequest(data, file, fileName)
                } else {
                    const {data} = await getSignature();
                    window.localStorage.setItem('monitor-signature-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem('monitor-subjectUuId'), JSON.stringify(data));
                    await this.uploadRequest(data, file, fileName)
                }
            },

            // 试卷袋上传
            async uploadBagHandle(file) {
                file.status = 'uploading';
                file.message = '上传中...';
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuId = localStorage.getItem('monitor-subjectUuId');
                const fileName = 'answer/' + zkz + '_' + subjectUuId + '_' + nanoid() + '.jpg';

                let signatureOss = window.localStorage.getItem('monitor-signature-' + localStorage.getItem('monitor-zkz') +'-'+ localStorage.getItem('monitor-subjectUuId'));
                if (signatureOss && signatureOss !== '{}' && Object.keys(JSON.parse(signatureOss)).length > 0) {
                    const data = JSON.parse(signatureOss);
                    await this.uploadRequest(data, file, fileName)
                } else {
                    const {data} = await getSignature();
                    window.localStorage.setItem('monitor-signature-' + localStorage.getItem('monitor-zkz') +'-'+ localStorage.getItem('monitor-subjectUuId'), JSON.stringify(data));
                    await this.uploadRequest(data, file, fileName)
                }
            },
            // 上传请求
            async uploadRequest(data, file, fileName) {
                const uploadData = await fileUpload(data, file.file, fileName);
                if (uploadData.data.Status === 'OK') {
                    file.status = 'success';
                    file.message = '上传成功';
                    file.fileName = fileName
                    this.fileList = [{
                        url: uploadData.data.url,
                        status: 'success'
                    }];
                    this.imgUrl = uploadData.data.url;
                    this.loading = false;
                    this.isUpload = true;
                    Toast('上传成功');
                } else {
                    file.status = 'failed';
                    file.message = '上传失败';
                    this.fileList = [];
                    Toast('上传失败');
                }
            },
        },
        watch: {
            dialogShowFu() {
                this.dialogShow = this.dialogShowFu;
            },
            formDataFu() {
                this.formData = this.formDataFu;
            }
        }
    }
</script>

<style scoped lang="scss">
    .upload-sample {
        width: 90% !important;
        max-width: 400px;
        max-height: 85%;
        display: flex;
        flex-flow: column;

        ::v-deep .van-dialog__content {
            flex: 1;
            padding: 25px 20px 128px;
            overflow-y: scroll;
            text-align: center;
            font-family: "PingFang SC";

            .hint-txt {
                text-align: left;
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 10px 0;

                span {
                    color: #F5A43C;
                }
            }

            .hint-img {
                width: 100%;

                .van-image {
                    /*width: 60%;*/
                    /*min-width: 200px;*/
                    /*min-height: 90px;*/
                    border-radius: 8px;

                    .van-image__img {
                        width: 60%;
                        min-width: 200px;
                        min-height: 90px;
                        border-radius: 8px;
                        margin: 0 auto;
                    }
                }

                .img-txt {
                    text-align: center;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 5px 0 10px;
                }
            }

            .hint-btn {
                background: rgba(255, 255, 255, 0.9);
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 0 25px 20px;

                .confirm {
                    width: 100%;
                    border-radius: 8px;
                    font-size: 14px;
                    color: #ffffff;
                    border-color: #563279;
                    background-color: #563279;

                    .van-uploader__input-wrapper {
                        width: 100%;
                    }
                }

                .cancel {
                    border-radius: 8px;
                    font-size: 14px;
                    color: #1989fa;
                    text-decoration: underline;
                    border-color: transparent;
                    background-color: transparent;

                    &::before {
                        background-color: transparent !important;
                    }
                }
            }

            .commit-btn {
                /*background: rgba(255,255,255,0.9);*/
                /*position: absolute;*/
                /*left: 0;*/
                /*bottom: 0;*/
                /*width: 100%;*/
                /*padding: 0 25px 20px;*/
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                align-items: center;

                .confirm {
                    flex: 1;
                    border-radius: 8px;
                    font-size: 14px;
                    color: #ffffff;
                    border-color: #563279;
                    background-color: #563279;

                    .van-uploader__input-wrapper {
                        width: 100%;
                    }
                }

                .cancel {
                    flex: 1;
                    border-radius: 8px;
                    font-size: 14px;
                    color: #ffffff;
                    border-color: #F5A43C;
                    background-color: #F5A43C;
                    margin-right: 10px;

                    .van-uploader__input-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }

    .isUpload {
        ::v-deep .van-dialog__content {
            padding: 25px 20px 128px;

        }
    }
</style>

