<template>
    <div class="upload-container">
        <div class="upload_header">
            <van-nav-bar
                    :title="timeHandle($store.state.timeUpload)"
                    @click-left="cancel"
                    @click-right="commit"
                    left-text="取消"
                    right-text="提交"/>
        </div>
        <div class="upload_content">
            <div class="subject-name">{{allSubjectName}}作品</div> <!--及封袋照片上传-->
            <div class="subject-text">
                第1步：点击下方“+”查看作品拍照要求并按要求拍照上传。如对拍摄照片不满意，可删除照片重新拍照。
            </div>
            <!--作品上传-->
            <div v-for="(item, index) in cateList" :key="index">
                <div v-for="(item2, index2) in item.value" :key="index2">
                    <div class="upload-item">
                        <div v-if="fileList[index]&&fileList[index]['value'][index2]&&fileList[index]['value'][index2]!=''&&fileList[index]['value'][index2]!=null"
                             class="upload-item-yes">
                            <van-image :src="fileList[index]['value'][index2][0]['url']" fit="cover"
                                       @click="viewImage([fileList[index]['value'][index2][0]['url']])"></van-image>
                            <van-icon name="cross"
                                      @click="beforeDeleteAnswer(fileList[index]['value'][index2][0]['url'], index,index2,item.subjectUuid)"/>
                        </div>
                        <div v-else class="upload-item-no" @click="openDialog(index,index2,item.subjectUuid, 1)">
                            <van-icon name="plus"/>
                            <div class="upload-text">{{item.name}}作品</div>
                        </div>
                    </div>
                </div>
            </div>
           <!-- <div class="subject-text">
                第2步：将<span>{{allSubjectName}}</span>作品放入正式寄回袋中，并使用“清华大学美术学院专业试卷密封条”封袋。<span>为避免影响成绩，封袋前确认上传的照片符合要求。</span>
            </div>

            <div class="subject-text">
                第3步：点击下方“+”查看正式寄回袋拍照要求并按要求拍照上传。
            </div> 袋子不需要baiansen -->

            <!--试卷袋上传-->
           <!-- <div class="upload-item">
                <div v-if="packageBag"
                     class="upload-item-yes">
                    <van-image :src="packageBag" fit="cover"
                               @click="viewImage([packageBag])"></van-image>
                    <van-icon name="cross" @click="deletePaperBag"/>
                </div>
                <div v-else class="upload-item-no" @click="openBagDialog(2)">
                    <van-icon name="plus"/>
                    <div class="upload-text">试卷袋</div>
                </div>
            </div> 袋子不需要baiansen -->
        </div>

        <!--上传示例弹框-->
        <upload-works
                :dialogShowFu="dialogShowFu"
                :formDataFu="formDataFu"
                @closeDialog="closeDialog"
        ></upload-works>
    </div>
</template>

<script>
    import {djTime} from "store/time";
    import {submitAnswers, getAnswer, getSubjectCategories} from "r/index/index";
    import {getChannelStatus} from "r/index/monitor";
    import {Toast, Dialog, ImagePreview} from "vant";
    import UploadWorks from "c/index/UploadWorks";
    import hintImg0 from "a/images/index/upload/img-1.png";
    import hintImg2 from "a/images/index/upload/img-2.png";

    export default {
        name: "upload",
        components: {UploadWorks},
        data() {
            return {
                isDisabled: false, // true-已经提交，禁用 false-可以提交
                isChecked: false, // 防重复点击
                cateList: [],
                fileList: [],
                allCount: 0, // 总题数
                newCount: 0, // 当前已上传的提数
                packageBag: null,// 试卷袋图片
                hintImg: hintImg0,// 作品示例图
                hintBagImg: hintImg2,// 作试卷袋示例图
                allSubjectName: null,
                dialogShowFu: false,// 控制上传示例弹框显示与隐藏
                formDataFu: {},
            }
        },
        created() {
            this.getSubCateInfo()
        },
        methods: {
            // 判断是否可以提交作品答案
            getChannelStatus() {
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                getChannelStatus({
                    subjectUuid: subjectUuid,
                    zkzNum: zkz,
                    status: 1
                })
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return this.$message.error(res.data.message);
                        // 0-关闭 1-打开
                        if (res.data.data == 1) {
                            this.submitHandle();
                        } else {
                            Toast('已超过规定的交卷时间，请点击【请求通话】联系监考老师说明情况。');
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            timeHandle(val) {
                return djTime(val)
            },
            // 图片预览
            viewImage(arr) {
                ImagePreview(arr)
            },
            // 打开上传作品弹框
            openDialog(index, index2, subjectUuid, type) {
                // type 1-作品 2-试卷袋
                this.formDataFu = {
                    idx1: index,
                    idx2: index2,
                    subjectId: subjectUuid,
                    // hintImg: this.hintImg[index],
                    hintImg: this.hintImg,
                    type: type
                }
                this.dialogShowFu = true;
            },
            // 打开上传试卷袋弹框
            openBagDialog(type) {
                // type 1-作品 2-试卷袋
                this.formDataFu = {
                    hintImg: this.hintBagImg,
                    type: type
                }
                this.dialogShowFu = true;
            },
            // 子组件触发，关闭上传示例弹框
            closeDialog(val, imgUrl, uploadType, idx1, idx2) {
                if (this.isDisabled) {
                    return Toast('作品已提交，不能上传');
                }
                this.dialogShowFu = val;
                if (imgUrl) {
                    const zkz = localStorage.getItem('monitor-zkz');
                    const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                    if (uploadType == 1) {
                        this.newCount++;
                        let index = this.fileList[idx1]['value'][idx2].length;
                        this.fileList[idx1]['value'][idx2][index] = {
                            url: imgUrl,
                            status: 'success',
                            idx: index
                        };
                        let arr = this.fileList[idx1]['value'][idx2].map(item => item.url);
                        this.cateList[idx1]['value'][idx2] = arr.join(',');
                        this.$forceUpdate();
                        window.localStorage.setItem('monitor-filelist-' + zkz + '-' + subjectUuid, JSON.stringify(this.fileList));
                    } else if (uploadType == 2) {
                        this.packageBag = imgUrl;
                        this.$forceUpdate();
                        window.localStorage.setItem('monitor-packageBag-' + zkz + '-' + subjectUuid, this.packageBag);
                    }
                }
            },
            // 提交
            commit() {
                if (this.isDisabled) {
                    return Toast('不能重复提交');
                }
                if (this.$store.state.timeUpload <= 0) {
                    // 时间超时，先获取交卷状态
                    this.getChannelStatus()

                } else {
                    // 直接交卷
                    this.submitHandle();
                }
            },
            // 交卷方法
            submitHandle() {
                let newCount = 0;
                this.cateList.forEach((item) => {
                    for (let k in item['value']) {
                        if (item['value'][k] && item['value'][k].length > 0) {
                            // newCount +=item['value'][k].split(',').length;
                            newCount++;
                        }
                    }
                })
                if (newCount != this.allCount) {
                    return Toast('作品未上传完全')
                }
               /* if (!this.packageBag || this.packageBag == "" || this.packageBag == null) {
                    return Toast('试卷袋未上传')
                }*/

                // let subjectUuId = window.localStorage.getItem('monitor-subjectUuId');
                // let submit = localStorage.getItem('Submit' + subjectUuId);
                // if (submit != 1) {
                    // this.$emit("playAudio", "submit");
                    // localStorage.setItem('Submit' + subjectUuId, "1");
                // }

                Dialog.confirm({
                    title: '提示',
                    message: '确认提交吗？',
                })
                    .then(() => {
                        const zkz = localStorage.getItem('monitor-zkz');
                        const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                        const answer = JSON.stringify(this.cateList);
                        let obj = {
                            zkzNum: zkz,
                            subjectUuid: subjectUuid,
                            studentAnswerImages: answer,
                            packageBag: this.packageBag
                        }
                        // on confirm
                        submitAnswers(obj)
                            .then(res => {
                                console.log(res)
                                if (res.data.data == 'OK') {
                                    Toast('提交成功');

                                    for (let k in window.localStorage) {
                                        console.log(k)
                                        if (k.indexOf('monitor-filelist') != -1
                                            || k.indexOf('monitor-packageBag') != -1
                                            || k.indexOf('monitor-envir') != -1) {
                                            // 清空图片缓存
                                            window.localStorage.removeItem(k);
                                        }
                                    }

                                    window.localStorage.setItem('monitor-hintDialog', 'true');
                                    this.$router.replace('/monitor')
                                } else {
                                    Toast('提交失败');
                                }
                            })
                            .catch(err => {
                                Toast('提交失败');
                                console.log(err)
                            })
                    })
                    .catch(() => {
                        // on cancel
                        Toast('取消提交');
                    });
            },
            // 取消
            cancel() {
                console.log(!this.isDisabled && this.newCount > 0)
                if (!this.isDisabled && this.newCount > 0) {
                    Dialog.confirm({
                        title: '提示',
                        message: '作业未提交，是否取消？',
                    })
                        .then(() => {
                            this.$router.replace('/monitor');
                        })
                        .catch(() => {
                            // on cancel
                            // this.commit()
                        });
                } else {
                    this.$router.replace('/monitor');
                }
            },
            beforeDeleteAnswer(file, idx1, idx2, subjectId) {
                if (this.isDisabled) {
                    return Toast('作品已提交，不能删除');
                }
                this.fileList[idx1]['value'][idx2].splice(file.idx, 1);
                this.newCount--;
                let arr = this.fileList[idx1]['value'][idx2].map(item => item.url);
                this.cateList[idx1]['value'][idx2] = arr.join(',');
                this.$forceUpdate();
                window.localStorage.setItem('monitor-filelist-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem("monitor-subjectUuId"), JSON.stringify(this.fileList));
            },
            deletePaperBag() {
                if (this.isDisabled) {
                    return Toast('作品已提交，不能删除');
                }
                this.packageBag = "";
                this.$forceUpdate();
                window.localStorage.setItem('monitor-packageBag-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem("monitor-subjectUuId"), this.packageBag);
            },
            // 获取提交过的答案详情
            getAnswerInfo() {
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                let obj = {
                    subjectUuid: subjectUuid,
                    zkzNum: zkz
                }
                getAnswer(obj)
                    .then(res => {
                        console.log(res)
                        let data = res.data;
                        if (data.code != 200) {
                            Toast(data.message)
                        }
                        if (data.data.studentPaperBag == "" || data.data.studentAnswer == '') {
                            this.isDisabled = false;
                            // 未提交-读取缓存
                            // 作品缓存
                            let uploadList = window.localStorage.getItem('monitor-filelist-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem("monitor-subjectUuId"));
                            if (uploadList) {
                                this.fileList = JSON.parse(uploadList);
                                let cateLists = JSON.parse(uploadList);
                                this.fileList.forEach((item1, idx1) => {
                                    for (let k in item1.value) {
                                        let arr = this.fileList[idx1]['value'][k].map(item => item.url);
                                        cateLists[idx1]['value'][k] = arr.join(',');
                                        this.newCount += arr.length;
                                    }
                                })
                                this.cateList = cateLists;
                            }
                            // 试卷袋缓存
                            let packageBag = window.localStorage.getItem('monitor-packageBag-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem("monitor-subjectUuId"));
                            if (packageBag) {
                                this.packageBag = packageBag;
                            }
                        } else {
                            this.isDisabled = true;
                            this.cateList = JSON.parse(data.data.studentAnswer);
                            this.packageBag = data.data.studentPaperBag;
                            let fileList = JSON.parse(data.data.studentAnswer);
                            this.cateList.forEach((item1, idx1) => {
                                for (let k in item1.value) {
                                    if (!item1['value'][k] || item1['value'][k] == '' || item1['value'][k] == null) {
                                        fileList[idx1]['value'][k] = [];
                                    } else {
                                        let arr = this.cateList[idx1]['value'][k].split(',')
                                        fileList[idx1]['value'][k] = arr.map((item, index) => {
                                            return {
                                                url: item,
                                                idx: index,
                                                status: 'success'
                                            }
                                        })
                                        this.newCount++;
                                    }
                                }
                            })
                            this.fileList = fileList;
                            // window.localStorage.setItem('monitor-packageBag-' + zkz + '-' + subjectUuid, this.packageBag);
                            // window.localStorage.setItem('monitor-filelist-' + zkz + '-' + subjectUuid, JSON.stringify(this.fileList));
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 获取当前监控里边科目以及数量
            getSubCateInfo() {
                let subjectUuId = localStorage.getItem('monitor-subjectUuId');
                let zkz = localStorage.getItem('monitor-zkz');
                getSubjectCategories(subjectUuId, zkz)
                    .then(res => {
                        console.log(res)
                        let data = res.data;
                        if (data.code != 200) {
                            Toast(data.message)
                        }
                        let dateArr = JSON.parse(data.data);
                        let cateList = JSON.parse(data.data);
                        let fileList = JSON.parse(data.data);
                        let allCount = 0;
                        let allSubjectName = [];
                        dateArr.forEach((item, index) => {
                            allSubjectName.push(item.name);
                            item['value'] = item['value'].split(',')
                            allCount += item['value'].length
                            cateList[index]['value'] = {};
                            fileList[index]['value'] = {};
                            item['value'].forEach((item2) => {
                                cateList[index]['value'][item2] = null;
                                fileList[index]['value'][item2] = [];
                            })
                        })
                        this.allSubjectName = allSubjectName.join('与');
                        this.cateList = cateList;
                        this.fileList = fileList;
                        this.allCount = allCount;
                        this.getAnswerInfo()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        }
    }
</script>

<style scoped lang="scss">
    .upload-container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-flow: column;

        .upload_header {
            position: fixed;
            width: 100%;
            background-color: #ffffff;
            z-index: 10;

            ::v-deep .van-nav-bar {

                .van-nav-bar__left {
                    .van-nav-bar__text {
                        color: #ee0a24;
                    }
                }

                .van-nav-bar__title {
                    color: #F5A43C;
                }

                .van-nav-bar__right {
                    .van-nav-bar__text {
                        color: #8b2d87;
                    }
                }

            }
        }

        .upload_content {
            flex: 1;
            width: 95%;
            margin: 48px auto 2%;
            border: 2px dashed #aaaaaa;
            padding: 0 10px 10px;
            background-color: #fff;
            font-family: "PingFang SC";


            .subject-name {
                margin: 15px 0 8px;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
            }

            .subject-text {
                margin: 8px 0;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;

                span {
                    color: #F5A43C;
                }
            }


            .upload-item {
                width: 220px;
                height: 120px;
                background-color: #f5f5f5;
                /*border-radius: 8px;*/
                margin: 0 auto 8px;

                .van-image {
                    width: 100%;
                    height: 100%;
                }

                .upload-item-yes {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .van-icon-cross {
                        position: absolute;
                        right: 0;
                        top: 0;
                        transform: translate(50%, -50%);
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                        text-align: center;
                        border-radius: 50%;
                        background-color: #b2b2b2;
                        color: #fff;

                        &::before {
                            font-size: 16px;
                            -webkit-transform: scale(.5);
                            transform: scale(.5);
                        }
                    }
                }

                .upload-item-no {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .van-icon-plus {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: #E5E5E5;
                        font-size: 40px;
                    }

                    .upload-text {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 12px;
                        background-color: rgba(0, 0, 0, 0.4);
                        /*border-bottom-left-radius: 8px;*/
                        /*border-bottom-right-radius: 8px;*/
                    }
                }
            }

            ::v-deep .van-uploader {
                vertical-align: middle;

                .van-uploader__upload {
                    background-color: #f5f5f5;
                    border-radius: 8px;

                    .van-uploader__upload-icon {
                        color: #E5E5E5;
                        font-size: 40px;
                    }
                }

                .van-uploader__preview {
                    position: relative;
                    background-color: #f5f5f5;
                    border-radius: 8px;

                    .van-uploader__preview-delete {
                        position: absolute;
                        right: 0;
                        top: 0;
                        transform: translate(50%, -50%);
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #b2b2b2;

                        .van-icon {
                            top: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
</style>

